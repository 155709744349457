import { Box, rem, Text, Divider, Container, Title } from "@mantine/core";

export default function About({
  title,
  description,
}: {
  title: string;
  description: string;
}): JSX.Element {
  return (
    <Container size="xl" my="lg">
      <Box
        className="whitespace-pre-line border border-solid border-dark-5"
        p={rem(15)}
        mt="lg"
      >
        <Title
          order={2}
          fz="xs"
          className="uppercase"
          c="dark.9"
          fw={700}
          lh="xs"
        >
          {title}
        </Title>
        <Divider color="gray.2" my="xs" />
        <Text size="sm" c="dark.8">
          {description}
        </Text>
      </Box>
    </Container>
  );
}
